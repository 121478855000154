<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items"
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap px-2 pb-1 pt-3">
					<v-checkbox hide-details dense label="显示已禁用" v-model="showHidden" @change="loadData"/>
					<v-text-field label="用户名" v-model.trim="pusername" clearable hide-details dense style="flex: 0 1 auto" class="ml-8"
						append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
				</div>
				<v-divider/>
			</template>
            <template v-slot:item.name="props">
				<v-edit-dialog :return-value.sync="props.item.name" large save-text="保存" cancel-text="取消" @save="saveItem('name', props)">
					{{props.item.name}}
					<template v-slot:input>
						<v-text-field label="姓名" v-model="props.item.name"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.title="props">
				<v-edit-dialog :return-value.sync="props.item.title" large save-text="保存" cancel-text="取消" @save="saveItem('title', props)">
					{{props.item.title}}
					<template v-slot:input>
						<v-text-field label="职务" v-model="props.item.title"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.region="props">
				<v-edit-dialog :return-value.sync="props.item.region" large save-text="保存" cancel-text="取消" @save="saveItem('region', props)">
					{{props.item.region}}
					<template v-slot:input>
						<v-select label="诊所" :items="regions" multiple v-model="props.item.region"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.group="props">
				<v-edit-dialog :return-value.sync="props.item.group" large save-text="保存" cancel-text="取消" @save="saveItem('group', props)">
					{{props.item.group}}
					<template v-slot:input>
						<v-select label="组" :items="usergroup" clearable v-model="props.item.group" style="max-width:240px"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.role="props">
				<v-edit-dialog :return-value.sync="props.item.role" large save-text="保存" cancel-text="取消" @save="saveItem('role', props)">
					{{props.item.role}}
					<template v-slot:input>
						<v-select label="权限" :items="privileges" multiple v-model="props.item.role" style="max-width:360px"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.restrictIP="props">
				<v-edit-dialog :return-value.sync="props.item.restrictIP" large save-text="保存" cancel-text="取消" @save="saveItem('restrictIP', props)">
					{{props.item.restrictIP}}
					<template v-slot:input>
						<v-checkbox v-model="props.item.restrictIP" label="限IP"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.disabled="props">
				<v-edit-dialog :return-value.sync="props.item.disabled" large save-text="保存" cancel-text="取消" @save="saveItem('disabled', props)">
					{{props.item.disabled}}
					<template v-slot:input>
						<v-checkbox v-model="props.item.disabled" label="停用"/>
					</template>
				</v-edit-dialog>
			</template>
            <template v-slot:item.resetPW="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon v-bind="attrs" v-on="on" @click.stop="resetPW(item)">lock_reset</v-icon>
					</template>
					重置密码
				</v-tooltip>
			</template>
            <template v-slot:footer.prepend>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
		                <v-icon v-bind="attrs" v-on="on" large color="primary" class="ml-4 mt-3" @click.stop="addItem">mdi-account-plus</v-icon>
					</template>
					添加用户
				</v-tooltip>
            </template>
        </v-data-table>
		<v-dialog v-model="dialog" persistent max-width="290">
			<v-card>
				<v-card-title>添加用户</v-card-title>
				<v-card-text>
					<v-form ref="userform">
						<v-text-field label="用户名" v-model.trim="newuser.username" :rules="usernameRules" :error-messages="usernameerr"/>
						<v-text-field label="密码" type="password" v-model.trim="newuser.password0" :rules="passwordRules"/>
						<v-text-field label="重复输入密码" type="password" v-model.trim="newuser.password1" :rules="passwordRules" :error-messages="password1err"/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn @click.stop="dialog=false">取消</v-btn>
					<v-btn color="primary" @click.stop="saveNew" :loading="loadingnew" :disabled="loadingnew">保存</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    export default {
		name: 'User',
        data() {
            return {
                authed: false,
				regions: [],
				usergroup: {},
                headers: [
                    {text:'用户名', value:'username', width:100},
                    {text:'姓名', value:'name', width:100},
                    {text:'职务', value:'title', width:120},
                    {text:'诊所', value:'region', width:180},
                    {text:'组', value:'group', width:100},
                    {text:'权限', value:'role', width:240},
                    {text:'限IP', value:'restrictIP', width:100},
                    {text:'禁用', value:'disabled', width:100},
                    {text:'重置密码', value:'resetPW', width:100},
                ],
                items: [],
				loading: false,
				options: {sortBy:['username'], sortDesc:[false]},
				totalItems: 0,
				dialog: false,
				usernameRules: [v => !!v || "此项为必填", v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字', v => v.length >= 2 || '至少2个字符'],
				passwordRules: [v => !!v || "此项为必填", v => (/^[0-9a-zA-Z]*$/g).test(v) || '只允许字母和数字', v => v.length >= 4 || '至少4个字符'],
				newuser: {
					username: '',
					password0: '',
					password1: ''
				},
				usernameerr: '',
				password1err: '',
				loadingnew: false,
				showHidden: false,
				pusername: null,
				privileges: [
					'全部客户总览',
					'预约接待',
					'修改问卷',
					'术前检查单',
					'修改检查单',
					'收讫检查单',
					'取消收讫',
					'取华兆报告',
					'术前评估',
					'修改评估',
					'修改病历',
					'病历管理',
					'消化内科',
					'麻醉科',
					'客户付款确认',
					'交付清肠药',
					'手术管理',
					'术中结算',
					'术中结算-只读',
					'保险预授权-只读',					
					'术后管理',
					'复诊开单',
					'复诊管理',
					'病理报告返回',
					'病理报告解读',
					'病理传递',
					'运营计划',
					'保留时段',
					'内镜报告',
					'打印条码',
					'查看全部任务',
					'健康顾问管理',
					'医生管理',
					'用户管理',
					'条目管理',
					'修改设置',
					'归档',
					'商务',
					'视频',
					'礼券',
					'门诊',
					'门诊计费',
					'其他收费',
					'心电图',
					'华三术前',
					'华兆',
					'统计',
					'限制查看',
					'查看日志'
				]
            }
        },
		mounted() {
			this.authed = this.$hasPrivilege('用户管理');
			if (!this.authed) return;
			const db = this.$tcbapp.database();
			db.collection('region').where({show:true}).orderBy('rank','asc').field({name:true}).get().then(res => {
				this.regions = res.data.map(v=>v.name);
			});
            db.collection('workset').doc('current').field({usergroup:true}).get().then(res => {
				this.usergroup = Object.keys(res.data[0].usergroup);
            }).catch(console.error);
			this.fetchData();
		},
		methods: {
			loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = {username:_.neq('admin')};
				if (!this.showHidden) {
					filter.disabled = false;
				}
				if (this.pusername) {
					filter.username = this.pusername;
				}
				this.loading = true;
				try {
                    const countRes = await db.collection('user').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('user').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            addItem() {
				this.newuser.username = '';
				this.newuser.password0 = '';
				this.newuser.password1 = '';
				this.usernameerr = '';
				this.password1err = '';
				this.dialog = true;
				this.$nextTick(() => {
					this.$refs.userform.resetValidation();
				});
			},
			async saveNew() {
				if (!this.$refs.userform.validate()) return;
				if (this.newuser.password0 !== this.newuser.password1) {
					this.password1err = '密码不一致';
					return;
				}
				this.loadingnew = true;
				try {
					const res = await this.$callCloudFunc({
						funcname:'addUser',
						data: {
							username: this.newuser.username,
							password: this.newuser.password0,
						}
					});
					if (res.result?.err) {
						this.usernameerr = res.result.err;
					} else {
						this.dialog = false;
						this.fetchData();
					}
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('添加用户失败');
				}
				this.loadingnew = false;
			},
			async saveItem(key, props) {
				try {
					const res = await this.$callCloudFunc({
						funcname:'editUser',
						data: {
							id: props.item._id,
							key,
							value: props.value,
						}
					});
					this.$dialog.message.success('已保存修改');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('修改用户失败');
				}
			},
			async resetPW(item) {
				const r = await this.$dialog.confirm({
					text: `确定要重置用户${item.username}的密码？`,
					title: '确认'
				});
				if (!r) return;
				const id = item._id;
				this.loading = true;
				try {
					const res = await this.$callCloudFunc({
						funcname:'resetPassword',
						data: {
							id,
						}
					});
					if (res.result.newpass) {
						this.$dialog.info({title:'重置密码', text:`密码已重置为${res.result.newpass}`});
					}
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('重置密码失败');
				} finally {
					this.loading = false;
				}
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
		},
	}
</script>